body {
    font-family: 'Inter', sans-serif;
}

.login-container {
    display: flex;
    height: 100vh; /* Garante que a altura seja de toda a viewport */
    font-family: 'Inter', sans-serif;
}

/* Coluna Esquerda */
.login-form-container {
    width: 40%;
    padding: 40px 20px; /* Espaçamento geral */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centraliza os elementos verticalmente */
    align-items: center;
    background-color: #fff !important;
}

.logo-top {
    width: 300px;
    margin-bottom: 150px; /* Espaço abaixo do logo */
}

.login-form {
    width: 100%;
    max-width: 300px;
}

.remember-checkbox {
    text-align: left;
    margin-bottom: 20px;

}

.remember-checkbox label {
    font-size: small;
    color: rgba(0, 66, 130, 1);
}

.forgot-password {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}


.forgot-password a {
    color: rgba(0, 66, 130, 1);
    text-decoration: none;
    font-size: small;
}

.alert-box {
    width: 100%;
    max-width: 300px;
    margin-top: 20px;
}

.logo-footer {
    width: 200px;
    margin-top: 40px; /* Espaçamento no final */
}

/* Coluna Direita */
.login-image-container {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    overflow: hidden; /* Garante que a imagem não ultrapasse os limites */
}

.login-image {
    width: 100%;
    height: 100vh; /* Faz a imagem ocupar toda a altura da página */
    object-fit: cover; /* Ajusta a imagem sem distorcer */
}

.button_login {
    background-color: rgba(224, 224, 224, 1);
    color: rgba(0, 0, 46, 0.85);
}

.button_login:hover {
    background-color: rgba(0, 0, 46, 0.85) !important;
    color: #fff;
}

@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
    }

    .login-form-container,
    .login-image-container {
        width: 100%;
        padding: 20px;
    }

    .login-image {
        visibility: hidden; /* Esconde a imagem */
    }

    .login-image-container {
        display: none; /* Esconde o container da imagem */
    }

    .login-form-container {
        height: 100vh; /* Garante que a altura seja de toda a viewport */

    }
}